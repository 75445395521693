let modalOpen = false

const modal = document.querySelector('.modal')
const cover = document.querySelector('.cover')
const contents = document.getElementById('content')

let cards = document.getElementsByClassName('work-img')


const OpenModal = (e)=>{
    modalOpen = true

    if(e.srcElement.id=="flygirl" || e.srcElement.parentElement.id=="flygirl" || e.srcElement.parentElement.parentElement.id=="flygirl"){
        modal.innerHTML =`
            <svg id="close" data-name="close" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112.81 112.81">
  <defs>
    <style>
      .cls-1 {
        fill: #2d2d2d;
        stroke-width: 0px;
      }
    </style>
  </defs>
  <path class="cls-1" d="M64.26,56.41L111.19,9.48c2.17-2.17,2.17-5.68,0-7.85s-5.68-2.17-7.85,0l-46.93,46.93L9.48,1.63C7.31-.54,3.79-.54,1.63,1.63s-2.17,5.68,0,7.85l46.93,46.93L1.63,103.34c-2.17,2.17-2.17,5.68,0,7.85,1.08,1.08,2.5,1.63,3.92,1.63s2.84-.54,3.92-1.63l46.93-46.93,46.93,46.93c1.08,1.08,2.5,1.63,3.92,1.63s2.84-.54,3.92-1.63c2.17-2.17,2.17-5.68,0-7.85l-46.93-46.93Z"/>
</svg>
        <div class="cover cover2 hidden"></div>
        <h1 class="modal-title">FLYGIRL</h1>
        <img class="modal-img" src="flygirl.png"/>
        <h2 class="modal-description">Micro Narrative + Web Based Storytelling + Vector Illustration</h2>
        <p class="project-description modal-description">FLY GIRL is a micro web-based narrative that employs dynamic and responsive SVG creation, frame-based web animation, and vector illustration to tell a unique story: many flies entering a black hole and transforming into a human.<br><br>

        This interactive experience unfolds with minimal user interaction, taking 0-2 clicks to complete. If the webpage is left untouched, the flies become increasingly restless, animating more vigorously over time. Each fly moves independently to convey a sense of natural and organic motion.<br><br>
        
        When users click the first button, a small black hole appears, attracting the flies which are then instantly drawn in upon contact. A subsequent button allows the user to “expand the black hole,” causing it to enlarge and then contract, ultimately forming the shape of a human.<br><br>
        
        This project originated from a sketch and was brought to life using Adobe Illustrator, HTML, and CSS. It exemplifies the fusion of artistic vision and web technology, creating an engaging and thought-provoking interactive experience.<br><br></p>
        <img class="modal-img" src="./flygirl2.png"/>
        <img class="modal-img" src="./blackhole.gif"/>
        <img class="modal-img" src="./flygirl4.JPG"/>
            <img class="modal-img" src="./flys.gif"/>
            `
    }else if (e.srcElement.id=="ponk" || e.srcElement.parentElement.id=="ponk"  || e.srcElement.parentElement.parentElement.id=="ponk"){
        modal.innerHTML =`
            <svg id="close" data-name="close" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112.81 112.81">
  <defs>
    <style>
      .cls-1 {
        fill: #2d2d2d;
        stroke-width: 0px;
      }
    </style>
  </defs>
  <path class="cls-1" d="M64.26,56.41L111.19,9.48c2.17-2.17,2.17-5.68,0-7.85s-5.68-2.17-7.85,0l-46.93,46.93L9.48,1.63C7.31-.54,3.79-.54,1.63,1.63s-2.17,5.68,0,7.85l46.93,46.93L1.63,103.34c-2.17,2.17-2.17,5.68,0,7.85,1.08,1.08,2.5,1.63,3.92,1.63s2.84-.54,3.92-1.63l46.93-46.93,46.93,46.93c1.08,1.08,2.5,1.63,3.92,1.63s2.84-.54,3.92-1.63c2.17-2.17,2.17-5.68,0-7.85l-46.93-46.93Z"/>
</svg>
        <div class="cover cover2 hidden"></div>
        <h1 class="modal-title">PONK V1</h1>
        <img class="modal-img" src="./ponk.jpeg"/>
        <h2 class="modal-description">Head-Mounted Controller + Inclusive Play + Top Down Interactive Projection</h2>
        <p class="project-description modal-description">

        Exploring the dynamic interplay between inputs and outputs, the objective was to transcend traditional user interfaces, moving beyond the simple touch of a finger on glass. Focusing on integrating Arduino technology to create innovative, physical, and digital experiences, this project remixes the classic game of Pong by putting the controller on the player's head and projecting the game onto a table.<br> <br>
        
        Inspired by the physical engagement of Wii controllers, particularly the delightful challenge they present in Mario Kart, these controllers utilize a gyroscope sensor and push the interaction further with a wearable head-mounted controller. Utilizing the Arduino Nano 33, its built-in gyroscope is harnessed to capture and transmit the tilt of each player’s head to a processing sketch via a web socket.<br> <br>
        
        The highlight of this project was the feedback I received during playtime. Many noted the controller’s potential to create inclusive play experiences, especially for individuals who can only move their heads. This insight was deeply gratifying and underscored the broader impact of thoughtful design.
        
        This project exemplified the fusion of physical and digital realms, bringing smiles and joy to users. It reinforced my passion for creating interactive and accessible experiences, showcasing my ability to innovate at the intersection of technology and human interaction.</p>
            <img class="modal-img" src="./ponk2.jpeg"/>
            <img class="modal-img" src="./ponk3.jpeg"/>
            <img class="modal-img" src="./ponk4.jpg"/>
            <img class="modal-img" src="./ponk5.jpeg"/>
            `
    }else if (e.srcElement.id=="networkgf" || e.srcElement.parentElement.id=="networkgf" || e.srcElement.parentElement.parentElement.id=="networkgf")
{ modal.innerHTML =`
<svg id="close" data-name="close" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112.81 112.81">
<defs>
<style>
.cls-1 {
fill: #2d2d2d;
stroke-width: 0px;
}
</style>
</defs>
<path class="cls-1" d="M64.26,56.41L111.19,9.48c2.17-2.17,2.17-5.68,0-7.85s-5.68-2.17-7.85,0l-46.93,46.93L9.48,1.63C7.31-.54,3.79-.54,1.63,1.63s-2.17,5.68,0,7.85l46.93,46.93L1.63,103.34c-2.17,2.17-2.17,5.68,0,7.85,1.08,1.08,2.5,1.63,3.92,1.63s2.84-.54,3.92-1.63l46.93-46.93,46.93,46.93c1.08,1.08,2.5,1.63,3.92,1.63s2.84-.54,3.92-1.63c2.17-2.17,2.17-5.68,0-7.85l-46.93-46.93Z"/>
</svg>
<div class="cover cover2 hidden"></div>
<h1 class="modal-title">NETWORK GF</h1>
<img class="modal-img" src="gf.jpeg"/>
<h2 class="modal-description">Wifi Acces Point Art + Ceiling Projection + Three.js</h2>
<p class="project-description modal-description">NETWORK GF is a WiFi network installation piece created as my final project for the “Radical Networks” class at NYU in Berlin. Visually inspired by early CGI, gorgons, and the Spring Sprite from Fantasia 2000, this low-poly digital femme features pulsing green vines for hair and a chrome-shaded body. Designed to be projected on the ceiling, her aesthetic complements the net and hacker art studio, Weise7, which graciously hosted us. As she rotates in a power stance above us, she calls out to be connected, with a marquee endlessly repeating “CONNECT WITH ME.”<br><br>

By scanning a QR code projected on the ceiling, visitors can connect to the “NETWORK GF” WiFi network. Upon connecting, a large pop-up notification announces to everyone that your device’s MAC address has connected, causing her to grow more powerful. A vine appears on the screen symbolizing the connection. If you disconnect, your device address is shared again, your vine disappears, and her power decreases.<br><br>

Utilizing the open-source project Subnodes, provided by my instructor, I transformed a Raspberry Pi into a WiFi access point. Each connection and disconnection to the network sent the MAC address to a data file used by the web app to manage the experience. When connection data was received, a new vine was created, added to the scene, and listed with its MAC address. Upon disconnection, the corresponding vine was removed from the scene and the data list.<br><br>

In the Radical Networks class, we explored the workings of the internet, net art, and hacktivism. This project allowed me to apply that knowledge in an installation that engaged and collaborated with the audience using an input that often feels like a black box. The experience was both enlightening and exhilarating, reinforcing my passion for creating interactive digital art that fosters connection and engagement.<br><br></p>
<img class="modal-img" src="./gf2.jpg"/>
<img class="modal-img" src="./gf3.JPG"/>
`}else if (e.srcElement.id=="dlrs" || e.srcElement.parentElement.id=="dlrs" || e.srcElement.parentElement.parentElement.id=="dlrs")
{ modal.innerHTML =`
<svg id="close" data-name="close" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112.81 112.81">
<defs>
<style>
.cls-1 {
fill: #2d2d2d;
stroke-width: 0px;
}
</style>
</defs>
<path class="cls-1" d="M64.26,56.41L111.19,9.48c2.17-2.17,2.17-5.68,0-7.85s-5.68-2.17-7.85,0l-46.93,46.93L9.48,1.63C7.31-.54,3.79-.54,1.63,1.63s-2.17,5.68,0,7.85l46.93,46.93L1.63,103.34c-2.17,2.17-2.17,5.68,0,7.85,1.08,1.08,2.5,1.63,3.92,1.63s2.84-.54,3.92-1.63l46.93-46.93,46.93,46.93c1.08,1.08,2.5,1.63,3.92,1.63s2.84-.54,3.92-1.63c2.17-2.17,2.17-5.68,0-7.85l-46.93-46.93Z"/>
</svg>
<div class="cover cover2 hidden"></div>
<h1 class="modal-title">Diary of a LOWRES Student</h1>
<img class="modal-img" src="diary.jpeg"/>
<h2 class="modal-description">Virtual World + Story Telling + Real Time Shared Experience</h2>
<p class="project-description modal-description">Diary of a Low Res Student is an immersive online multiplayer diary crafted using three.js, socket.io, MongoDB, Blender, Illustrator, and a touch of personal emotion. Completed in just two weeks as my final project for the fall term at NYU, this endeavor was a synthesis of my learnings from the “Connections Lab” and “Critical Experiences” courses. The former emphasized creating full-stack web applications as artistic expressions, while the latter focused on research-based art practices.<br><br>

Inspired by the interplay of these subjects, I aimed to design a virtual space where visitors could explore the memories and experiences of my journey through the IMA Low Res program. Upon entering, visitors are prompted to share their names and choose whether they feel strong, kind, or creative that day. Each participant appears as a Kami character in coveralls, with the color of their attire reflecting their chosen feeling. These details are also displayed on the “who’s online?” board, and the color of any flowers they plant in the diary corresponds to their selected emotion. The persistence of these flowers is achieved through a database that stores their coordinates and colors.<br><br>

One of the most exciting challenges I faced was ensuring that each player’s character appeared and moved correctly on all other players’ screens. Initially, I attempted to display a static character model for each online player, but this approach was limited. I needed the models to update their positions and animations in real-time. To solve this, I stored data objects in an array that continuously tracked and updated each player’s position and actions. This allowed the animation player to accurately reflect movements based on the players' key inputs.<br><br>

This project was a rewarding experience that allowed me to blend technical skills with creative storytelling. Currently, I am expanding my knowledge in “Immersive Web” with a focus on shaders, aiming to push the boundaries of my capabilities even further.<br><br></p>
<div id="small-imgs">
<img class="modal-img"  src="./diary6.JPG"/>
<img class="modal-img"  src="./diary7.JPG"/>
</div>
<img class="modal-img" src="./diary3.jpeg"/>
<img class="modal-img" src="./diary4.png"/>
<img class="modal-img" src="./DLRS.jpg"/>
`}else if (e.srcElement.id=="theTimeIsCow" || e.srcElement.parentElement.id=="theTimeIsCow" || e.srcElement.parentElement.parentElement.id=="theTimeIsCow")
{ modal.innerHTML =`
<svg id="close" data-name="close" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112.81 112.81">
<defs>
<style>
.cls-1 {
fill: #2d2d2d;
stroke-width: 0px;
}
</style>
</defs>
<path class="cls-1" d="M64.26,56.41L111.19,9.48c2.17-2.17,2.17-5.68,0-7.85s-5.68-2.17-7.85,0l-46.93,46.93L9.48,1.63C7.31-.54,3.79-.54,1.63,1.63s-2.17,5.68,0,7.85l46.93,46.93L1.63,103.34c-2.17,2.17-2.17,5.68,0,7.85,1.08,1.08,2.5,1.63,3.92,1.63s2.84-.54,3.92-1.63l46.93-46.93,46.93,46.93c1.08,1.08,2.5,1.63,3.92,1.63s2.84-.54,3.92-1.63c2.17-2.17,2.17-5.68,0-7.85l-46.93-46.93Z"/>
</svg>
<div class="cover cover2 hidden"></div>
<h1 class="modal-title">The Time Is Cow</h1>
<img class="modal-img" src="theTimeIsCow.png"/>
<h2 class="modal-description">Virtual World + Audio-Reactive Shaders + Composition</h2>
<p class="project-description modal-description">The Time is Cow is an experimental web-based musical experience that delves into the realms of audio-reactive shaders, composition, motif, perspective, and Doja Cat’s "MOOO!" This innovative project creates an immersive journey through a world crafted with three.js, Blender, and GLSL.
<br><br>
In this dynamic environment, an animated camera guides the user through various visual compositions, manipulating the perspective to explore the interplay of angles and dimensions. What appears to be down can become up, and what seems flat can transform into a three-dimensional landscape. This fluid manipulation of perspective challenges the user’s perception and enhances the immersive experience.
<br><br>
Central to the visual aesthetic are the cow spot motifs, which are generated as shaders on the walls and 3D objects throughout the experience. These motifs react to the music, creating a symbiotic relationship between the audio and visual elements. As the camera moves, the user encounters different compositions that highlight the versatility and creativity of the visual motifs, making the experience both engaging and unpredictable.<br><br>

The integration of three.js allows for the creation of intricate and responsive 3D graphics, while Blender provides the tools for detailed modeling and animation. GLSL (OpenGL Shading Language) is utilized to develop the custom shaders that bring the cow spot motifs to life, reacting dynamically to the audio input.<br><br></p>

<img class="modal-img" src="theTimeIsCow2.png"/>
<img class="modal-img" src="theTimeIsCow3.png"/>
`}else if (e.srcElement.id=="techi3" || e.srcElement.parentElement.id=="techi3" || e.srcElement.parentElement.parentElement.id=="techi3")
{ modal.innerHTML =`
<svg id="close" data-name="close" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112.81 112.81">
<defs>
<style>
.cls-1 {
fill: #2d2d2d;
stroke-width: 0px;
}
</style>
</defs>
<path class="cls-1" d="M64.26,56.41L111.19,9.48c2.17-2.17,2.17-5.68,0-7.85s-5.68-2.17-7.85,0l-46.93,46.93L9.48,1.63C7.31-.54,3.79-.54,1.63,1.63s-2.17,5.68,0,7.85l46.93,46.93L1.63,103.34c-2.17,2.17-2.17,5.68,0,7.85,1.08,1.08,2.5,1.63,3.92,1.63s2.84-.54,3.92-1.63l46.93-46.93,46.93,46.93c1.08,1.08,2.5,1.63,3.92,1.63s2.84-.54,3.92-1.63c2.17-2.17,2.17-5.68,0-7.85l-46.93-46.93Z"/>
</svg>
<div class="cover cover2 hidden"></div>
<h1 class="modal-title">TECHI3 CLUB</h1>
<img class="modal-img" src="techi3Club.JPG"/>
<h2 class="modal-description">Technology Education + Community + Print Design</h2>
<p class="project-description modal-description">Largely inspired by the fork in my journey, financial status, and quality of life caused by my introduction to Daniel Shiffman's educational videos on YouTube, Techi3.club’s biggest icon is The Coding Train. My introduction to these videos was through a senior year course called “The Art of Programming” at a private art school. Recognizing the privilege of that experience, Techi3.club seeks to make the discovery of creative technology more accessible to the working class. Shiffman addresses his audience with kindness and equality, as if having a conversation with a friend about a funny situation that happened to him that morning. The Coding Train imagery is soft and lighthearted, making the learning space unintimidating. Techi3.club aims to emulate these qualities through its color palette, illustrations, and writing.<br><br>

In the United States, Highlights magazine is a staple in many health offices, children’s homes, and libraries. Filled with activities and a few articles, it offers educational content and brain-stimulating puzzles. Techi3.club aspires to be similar but aimed at an older audience, tackling more complex topics with additional articles.<br><br>

While Wired magazine exists in the tech space, it often focuses on long-form content and billionaire-centric topics, which don't resonate with my target audience. Instead, I look to Make Magazine, which highlights projects, provides step-by-step guides, and introduces complex technology in an accessible manner. For instance, Make compared multi-color 3D printers without using alienating technical terms, making the information digestible for everyone. The contributors are relatable; one issue I referenced featured someone I knew. This relatability is crucial for nurturing a community, and Techi3.club aims to spotlight similar level creators and their projects. An example includes the fashion issue highlighting a classmate incorporating broken iPhone pieces into nail art at a salon.<br><br>

Techi3.club's mission is to bridge the gap in tech education, offering an inclusive and engaging platform that mirrors the supportive and approachable nature of Shiffman's teachings while fostering a community-centric environment.<br><br></p>

<div id="small-imgs">
<img class="modal-img"  src="./techi32.JPG"/>
<img class="modal-img" src="./techi33.JPG"/>
</div>
`}else if (e.srcElement.id=="streemNav" || e.srcElement.parentElement.id=="streemNav" || e.srcElement.parentElement.parentElement.id=="streemNav")
{ modal.innerHTML =`
<svg id="close" data-name="close" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112.81 112.81">
<defs>
<style>
.cls-1 {
fill: #2d2d2d;
stroke-width: 0px;
}
</style>
</defs>
<path class="cls-1" d="M64.26,56.41L111.19,9.48c2.17-2.17,2.17-5.68,0-7.85s-5.68-2.17-7.85,0l-46.93,46.93L9.48,1.63C7.31-.54,3.79-.54,1.63,1.63s-2.17,5.68,0,7.85l46.93,46.93L1.63,103.34c-2.17,2.17-2.17,5.68,0,7.85,1.08,1.08,2.5,1.63,3.92,1.63s2.84-.54,3.92-1.63l46.93-46.93,46.93,46.93c1.08,1.08,2.5,1.63,3.92,1.63s2.84-.54,3.92-1.63c2.17-2.17,2.17-5.68,0-7.85l-46.93-46.93Z"/>
</svg>
<div class="cover cover2 hidden"></div>
<h1 class="modal-title">Streem Navigation Sidebar</h1>
<img class="modal-img" src="sideNav2.png"/>
<h2 class="modal-description">React + CSS Animations + Collaboration with Design</h2>
<p class="project-description modal-description">When creating the side navigation for the Streem Web App, I worked closely with our designer to ensure her vision came to life exactly as intended. Coming from a design background myself, I understand how important it is to translate the designer’s work with the same care and attention they put into it.
<br><br>
We had a lot of productive back-and-forth, particularly around how the animation should flow and the exact speed it should have. We even used a screen recording to review the animation frame by frame, which helped us catch and fix a slight jitter, perfecting the final result.
<br><br>
The navigation bar has two persistent states: one for users who prefer just icons and another for those who benefit from more descriptive navigation. We created a simple, design-cohesive tab that allows users to easily toggle between these two states. Plus, when users log out and back in, they’ll find the nav bar just as they left it.
<br><br>
This project was all about balancing design intention with functionality, ensuring a smooth and personalized user experience.<br><br></p>
<img class="modal-img" src="sideNavStudy1.png"/>
<img class="modal-img" src="sideNavStudy2.png"/>
<img class="modal-img" src="sideNav1.png"/>
<img class="modal-img" src="sideNav3.png"/>

`}else if (e.srcElement.id=="streemLogin" || e.srcElement.parentElement.id=="streemLogin" || e.srcElement.parentElement.parentElement.id=="streemLogin")
{ modal.innerHTML =`
<svg id="close" data-name="close" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112.81 112.81">
<defs>
<style>
.cls-1 {
fill: #2d2d2d;
stroke-width: 0px;
}
</style>
</defs>
<path class="cls-1" d="M64.26,56.41L111.19,9.48c2.17-2.17,2.17-5.68,0-7.85s-5.68-2.17-7.85,0l-46.93,46.93L9.48,1.63C7.31-.54,3.79-.54,1.63,1.63s-2.17,5.68,0,7.85l46.93,46.93L1.63,103.34c-2.17,2.17-2.17,5.68,0,7.85,1.08,1.08,2.5,1.63,3.92,1.63s2.84-.54,3.92-1.63l46.93-46.93,46.93,46.93c1.08,1.08,2.5,1.63,3.92,1.63s2.84-.54,3.92-1.63c2.17-2.17,2.17-5.68,0-7.85l-46.93-46.93Z"/>
</svg>
<div class="cover cover2 hidden"></div>
<h1 class="modal-title">Streem Login Page</h1>
<img class="modal-img" src="StreemLogin.png"/>
<h2 class="modal-description">React + Generative SVG + Collaboration with Design</h2>
<p class="project-description modal-description">The login page for the Streem Web App was a collaborative effort with the design team, who envisioned a stunning gradient with a triangle grid system reminiscent of a 3D mesh, alongside a clean dialogue box that highlights the logo. Instead of relying on large image files for different breakpoints, I developed a dynamic, generative SVG React component that creates a perfect grid of lines based on the browser size, updating seamlessly with window resizing. Behind this, I used a CSS linear gradient to match the design from the Figma file and applied a CSS radial gradient that fades to transparent, beautifully highlighting the logo.
<br><br>
The dialogue box on the right required careful attention to detail to ensure it looked just as beautiful as the design file while integrating seamlessly with our Streem ecosystem. The goal was to provide users with a smooth login experience and clearly display an error message if something went wrong. This part of the project involved close collaboration with QA to ensure our error messaging was both smart and user-friendly.
<br><br>
This project was a blend of design precision and technical problem-solving, all aimed at creating a visually striking and functional login experience for our users.<br><br></p>
<img class="modal-img" src="streemLogin2.png"/>

`}else if (e.srcElement.id=="streemAPI" || e.srcElement.parentElement.id=="streemAPI" || e.srcElement.parentElement.parentElement.id=="streemAPI")
{ modal.innerHTML =`
<svg id="close" data-name="close" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112.81 112.81">
<defs>
<style>
.cls-1 {
fill: #2d2d2d;
stroke-width: 0px;
}
</style>
</defs>
<path class="cls-1" d="M64.26,56.41L111.19,9.48c2.17-2.17,2.17-5.68,0-7.85s-5.68-2.17-7.85,0l-46.93,46.93L9.48,1.63C7.31-.54,3.79-.54,1.63,1.63s-2.17,5.68,0,7.85l46.93,46.93L1.63,103.34c-2.17,2.17-2.17,5.68,0,7.85,1.08,1.08,2.5,1.63,3.92,1.63s2.84-.54,3.92-1.63l46.93-46.93,46.93,46.93c1.08,1.08,2.5,1.63,3.92,1.63s2.84-.54,3.92-1.63c2.17-2.17,2.17-5.68,0-7.85l-46.93-46.93Z"/>
</svg>
<div class="cover cover2 hidden"></div>
<h1 class="modal-title">Streem API Page and Modal</h1>
<img class="modal-img" src="streemAPI1.png"/>
<h2 class="modal-description">React + Collaboration with Backend + Collaboration with Design</h2>
<p class="project-description modal-description">Everything on the backend was already set by the time I got to the project, so my focus was largely on new page create and the birth of our modal component. Working closely with the designer we nit picked on where inputs, text, and headers could dynamically placed easily within the modal and created a set of rules around this. We also focused on how comfortable and usable the “x” button was when various content filled the modal. We also wanted user focus to be on the modal when it was open and not to make the page too busy to look at, so we created a tinted cover that went over the rest of the page and also listened for a click event in case the user wanted to close the modal by clicking outside of it.
<br><br>
For the Streem Web App, I worked on creating the first-ever modal component, specifically for the API page. The goal was to give our super users an easy way to create, access, and delete API keys. Before this, getting API keys was a bit of a hassle—it involved one of our engineers running scripts and then manually sharing the keys. Needless to say, this wasn’t ideal for anyone.
<br><br>
By the time I jumped into the project, the backend was all set, so my focus was on designing a new page and developing our modal component from scratch. I collaborated closely with the designer, and we got pretty detailed about how inputs, text, and headers should be dynamically placed within the modal. We even created a set of rules to ensure everything fit together smoothly.
<br><br>
We also paid special attention to the “x” button, making sure it was comfortable and easy to use, no matter what content filled the modal. Another key focus was keeping the user’s attention on the modal when it was open, without making the addition of it on the page too overwhelming. To achieve this, we added a tinted cover that darkens the rest of the page and also listens for a click outside the modal, allowing users to close it with a simple click.
<br><br>
This project was all about making the user experience smooth and intuitive. It was really satisfying to create an important piece of our system that has since been used in many places and to see how the new page and modal streamlined what used to be a clunky process.<br><br></p>
<img class="modal-img" src="modalUse2.png"/>
<img class="modal-img" src="modalUse1.png"/>

`}

const close = document.getElementById('close')

    close.addEventListener("click", ()=>{
        modalOpen=!modalOpen
        if(!modalOpen){
            modal.classList.add("hidden")
            cover.classList.add("hidden")
        }
    })
    cover.addEventListener('click', ()=>{
        cover.classList.add('hidden')
        modal.classList.add('hidden')
        modalOpen = false
    })

    modal.style.top = window.scrollY + "px"
    contents.style.height = "100%";
    contents.style.display= "hidden";
    contents.style.top = -window.scrollY + "px";
    contents.style.overflowY = "hidden";
    // modal.style.overflowY = "scroll";

    cover.classList.remove('hidden')
    modal.classList.remove('hidden')
}

cards.forEach(card => {
    card.addEventListener('click', OpenModal)
});

let enterPassword = document.getElementById('enterPassword')
let work = document.getElementById('work')
let input = document.getElementById('passwordInput')
let button = document.getElementById('passwordSubmit')


button.addEventListener('click',()=>{
    if(input.value==import.meta.env.VITE_KEY){
//--------newcard 1-------------------
        let newCard1 = document.createElement('div')
        newCard1.innerHTML = `
        <div class="bar">
            <p>

                Streem Login Page
            </p>
        </div>
        `
        newCard1.id = "streemLogin"
        newCard1.classList.add("work-img")
        enterPassword.after(newCard1)
        newCard1.addEventListener('click', OpenModal)

//--------newcard 2-------------------
        let newCard2 = document.createElement('div')
        newCard2.innerHTML = `
        <div class="bar">
            <p>

                Streem Navigation Sidebar
            </p>
        </div>
        `
        newCard2.id = "streemNav"
        newCard2.classList.add("work-img")
        enterPassword.after(newCard2)
        newCard2.addEventListener('click', OpenModal)

//--------newcard 3-------------------
        let newCard3 = document.createElement('div')
        newCard3.innerHTML = `
        <div class="bar">
            <p>
                Streem API Page and Modal
            </p>
        </div>
        `
        newCard3.id = "streemAPI"
        newCard3.classList.add("work-img")
        enterPassword.after(newCard3)
        newCard3.addEventListener('click', OpenModal)

        work.removeChild(enterPassword)
    }
})
